import React, { PropsWithChildren } from 'react'
import { Container } from '@/components/container'
import { SparklesCore } from '@/components/sparkles'
import { useTheme } from '@/components/theme-provider'

export default function MiniLayout({ children }: PropsWithChildren) {
    const { theme, setTheme } = useTheme()
    return (
        <Container>
            <div className="absolute inset-0 h-screen w-full">
                <SparklesCore
                    id="tsparticlesfullpage"
                    minSize={theme === 'light' ? 0.5 : 0.1}
                    maxSize={theme === 'light' ? 1.5 : 0.8}
                    particleDensity={20}
                    className="h-full w-full"
                    particleColor={theme === 'light' ? '#09090B' : '#FAFAFA'}
                    speed={2}
                />
            </div>
            <main className="flex">{children}</main>
        </Container>
    )
}
